.fade-exit div {
  max-height: 50px;
  opacity: 1;
}

.fade-exit-active div {
  opacity: 0;
  max-height: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: hidden;
  transition: opacity 250ms ease-in, padding-top 250ms ease-in 250ms,
    padding-bottom 250ms ease-in 250ms, max-height 250ms ease-in 250ms;
}

.baleen-row-open {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.baleen-row-close {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: padding-top 250ms ease-in 250ms,
    padding-bottom 250ms ease-in 250ms;
}
