body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Urbanist',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(
    var(--min-font-pixels) + (var(--max-font-size) - var(--min-font-size)) *
      (
        (100vw - var(--min-screen-width-pixels)) /
          (var(--max-screen-width-size) - var(--min-screen-width-size))
      )
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.highcharts-title {
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}