.footer-creator-info {
  font-family: 'Urbanist', 'Inter', sans-serif;
  line-height: 24px;
  font-weight: 700;
  font-size: 18px;
}

.footer-paragraph {
  font-family: 'Inter', 'Urbanist', sans-serif;
  line-height: 22px;
  font-weight: 700;
  font-size: 14px;
}

@font-face {
  font-family: 'Urbanist';
  src: url('../../Common/assets/fonts/Urbanist-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Urbanist';
  src: url('../../Common/assets/fonts/Urbanist-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('../../Common/assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('../../Common/assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('../../Common/assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
